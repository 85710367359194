@import "../_variables";
@import "../_mixins";

.disciplines-container{
  padding: 10px 0 44px;

  .heading{
    font-size: 25px;
    line-height: 40px;
  }
  .disciplines{
    display: block;
    margin-top: 15px;

    .discipline-block{
      margin: 0 auto;
      height: 300px;
      .description{
        padding: 10px 20px;
        max-height: fit-content;
        height: 118px;
        .title{
          margin-top: 0;
        }
        .text{
          margin-top: 4px;
          opacity: 1;
        }
        .button-more-not-desktop{
          display: flex;
        }
        .button-more{
          display: none;
        }
        .discipline-line{
          display: block;
          border: none;
          width: 216px;
          margin:0;
          height: 0;
          padding: 0;
          z-index: 1;
          position: relative;
          background: #fff;
          border-bottom: 0 solid $main_dark;
          transition: all ease 0.3s;
          &.show{
            margin: 0 auto 10px;
            padding: 10px 0 0;
            border-width: 1px;
          }
        }
      }
    }
  }

  .discipline-content-container{
    display: block;
    max-height: 0;
    transition: max-height ease 0.3s;
    width: 256px;
    margin: -10px auto 0;
    overflow-y: scroll;

    &.opened{
      max-height: 250px;
      .discipline-line{
        height: 1px;
      }
    }

    .discipline-content{
      background: #ffffff;
      position: relative;
      z-index: 1;
      padding: 0 20px 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      .button-close-not-desktop{
        display: block;
        margin-top: 12px;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $main_turquoise;
        text-transform: uppercase;
        img{
          transform: rotate(180deg);
        }
        span{
          margin-left: 5px;
        }
      }
    }
  }
}
