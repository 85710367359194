@import "../_variables";
@import "../_mixins";

.disciplines-container{
  padding: 20px 0 32px;
  .heading{
    font-size: 30px;
    line-height: 40px;
  }
  .disciplines{
    margin-top: 28px;

    .discipline-block{
      width: 215px;
      margin-bottom: 12px;
      height: 300px;
      .description{
        height: 142px;
        max-height: 142px;
        padding: 10px 18px 16px;
        .title{
          margin-top: 0;
          min-height: 34px;
        }
        .text{
          opacity: 1;
          font-size: 12px;
        }
        .button-more-not-desktop{
          display: flex;
        }
        .button-more{
          display: none;
        }
      }
    }
  }
}
