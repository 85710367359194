@import "../_variables";
@import "../_mixins";

.main-header{
  .main-nav{
    .logo{
      img{
        height: 53px;
      }
    }
    ul{
      li{
        margin-right: 10px;
        a{
          &:hover{
            border-bottom: none;
            font-weight: 300;
          }
        }
      }
    }
  }
}
