@import "../_variables";
@import "../_mixins";

.main-gallery{
  padding: 20px 0 40px;

  .slider {
    width: auto;
    .image-block{
      //padding-right: 20px;
      width: 256px !important;
      margin: 0 20px;
      img {
        height: 256px;
        width: 266px;
      }
    }
  }
}
