@import "_variables";
@import "_mixins";

.about{
  padding: 60px 0 64px;
  overflow: hidden;
  .main-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 400px;
  }
  .about-content{
    margin-right: 16px;
    margin-top: 10px;
    .heading{
      color: $main_turquoise;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 80px;
      line-height: 40px;
    }
    .description{
      margin-top: -8px;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 40px;
      color: $main_dark;
      font-weight: 900;
      span{
        color: $main_turquoise;
      }
    }
    .text{
      margin-top: 30px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-right: 120px;
    }
  }
  .gallery{
    min-width: 435px;
    max-width: 435px;
    height: 320px;
    .active-image{
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      transition: opacity ease 0.3s;
      &.show{
        opacity: 1;
        transition: opacity ease 0.1s;
      }
      .video-react{
        width: inherit;
        height: inherit;
        .video-react-control-bar{
          z-index: 1000;
        }
        .video-react-big-play-button{
          border-radius: 50px;
          width: 1.5em;
          margin-left: -1em;
        }
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
    //.list-image{
    //  width: 394px;
    //  margin-top: 20px;
    //  &:after{
    //    content: "";
    //    position: absolute;
    //    right: -20px;
    //    bottom: 0;
    //    width: 20px;
    //    height: 124px;
    //    background: #ffffff;
    //  }
    //  .gallery-slider{
    //    .image-block{
    //      width: 120px !important;
    //      height: 94px;
    //      cursor: pointer;
    //      box-sizing: border-box;
    //      .video-react{
    //        width: inherit;
    //        height: inherit;
    //        .video-react-big-play-button{
    //          border-radius: 50px;
    //          width: 1.5em;
    //          margin-left: -1em;
    //          display: none;
    //        }
    //      }
    //      //.video-block{
    //      //  position: relative;
    //      //  width: inherit;
    //      //  height: inherit;
    //      //  .play-button{
    //      //    position: absolute;
    //      //    width: inherit;
    //      //    height: inherit;
    //      //    z-index: 100000;
    //      //  }
    //      //}
    //      img{
    //        width: 100%;
    //        height: 100%;
    //      }
    //      //video{
    //      //  width: inherit;
    //      //  height: inherit;
    //      //}
    //    }
    //    .slick-center{
    //      .image-block{
    //        border: 3px solid $main_turquoise;
    //        overflow: hidden;
    //      }
    //    }
    //  }
    //}
  }
  .about-ellipse{
    position: absolute;
    overflow: hidden;
    bottom:-440px;
    left:-400px;
    width: 578px;
    height: 578px;
    background-size: 100% 100%;
    background-image: url("../images/other/ellipse-turquoise.png");
    background-repeat: no-repeat;
    z-index: -1;

  }
}
