@import "../_variables";
@import "../_mixins";

.main-header{
  display: none;
}
.main-header-mobile{
  display: none;
  @include vertical-flex-center();
  justify-content: center;
  background-color: rgba(15,15,20,1);
  padding: 24px 0 16px;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 999999;
  .menu{
    position: fixed;
    height: calc(100vh - 84px);
    background-color: rgba(15,15,20,0.9);
    width: 100%;
    z-index: 999998;
    top:84px;
    padding-top: 50px;
    left:-100vw;
    transition: left ease 0.3s;
    &.opened{
      left: 0;
    }
    .main-nav{
      position: relative;
      padding: 0 20px 52px;
      ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li{
          font-size: 19px;
          line-height: 50px;
          font-weight: 300;

          a{
            color: $main_light_grey;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 8px;
            &.active,
            &:hover{
              font-weight: 500;
              border-bottom: solid 2px $main_turquoise;
            }
          }
        }
      }
      .social-contacts{
        position: absolute;
        bottom: 0;
        right: 20px;
        a{
          margin-left: 20px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
  }

  .set-menu{
    position: absolute;
    right: 20px;
    width: 50px;
    height: 50px;
    top:50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:before{
      content: " ";
      margin-bottom: 8px;
      background: $main_turquoise;
      width: 33px;
      height: 1px;
      position: absolute;
      top:18px;
      transition: all ease 0.3s;
    }
    &:after{
      content: " ";
      background: $main_turquoise;
      width: 21px;
      height: 1px;
      position: absolute;
      transition: all ease 0.3s;
    }

    &.opened{
      &:before{
        transform: rotate(45deg);
        top: 25px;
      }
      &:after{
        width: 33px;
        transform: rotate(-45deg);
      }
    }
  }
}
