@import "../_variables";
@import "../_mixins";

.under-header{
  margin-top: -80px;
  height: 510px;
  padding-bottom: 100px;
  .main-container{
    .ellipse-under-header{
      width: 475px;
      height: 475px;
      top: -350px;
      left: 380px;
    }
  }
  .name-site{
    margin-bottom: 50px;
  }
  .heading{
    font-size: 45px;
    line-height: 40px;
    margin-bottom: 8px;
  }
  .description{
    font-size: 28px;
    line-height: 34px;
  }
  .phone-number{
    margin-top: 50px;
  }
}
