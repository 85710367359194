@import "_variables";
@import "_mixins";

.main-contacts{
  padding: 80px 0 64px;
  overflow: hidden;
  .main-container{
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .content{
    .heading{
      font-size: 40px;
      font-weight: 900;
      color: $main_dark;
      text-transform: uppercase;
    }
    .content-block{
      margin-top: 20px;
      .heading-content-block{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        color: $add_light_grey;
      }
      .text-content-block{
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-top: 2px;
      }
    }
    .contacts-links{
      .contact-email{
        display: block;
        margin-top: 20px;
        color: $main_turquoise;
        font-size: 18px;
        font-weight: 700;
      }
      .social-links{
        margin-top: 22px;
        a{
          margin-right: 14px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .map{
    position: relative;
  }
  .contact-ellipse{
    position: absolute;
    overflow: hidden;
    bottom:-320px;
    right:-430px;
    width: 578px;
    height: 578px;
    background-size: 100% 100%;
    background-image: url("../images/other/ellipse-turquoise.png");
    background-repeat: no-repeat;
    z-index: -1;
  }
}
