@import "../_variables";
@import "../_mixins";

.main-gallery{
  padding: 40px 0 0;

  .slider {
    width: 632px;
    height: 450px;
    .image-block{
      margin:0 20px;
      padding-right: 0;
      width: 100%;
      img {
        height: 400px;
        width: 100%;
      }
    }
  }
}
