@import "../_variables";
@import "../_mixins";

.about{
  padding: 40px 0;
  .about-content{
    margin-right: 0;
    margin-top: 0;
    .heading{
      font-size: 58px;
    }
    .description{
      margin-top: -12px;
      font-size: 30px;
      line-height: 30px;
    }
    .text{
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      margin-right: 80px;
    }
  }
  .gallery{
    min-width: 320px;
    .active-image{
      height: 222px;
      width: 320px;
    }
    .list-image{
      width: 326px;
      margin-top: 14px;
      &:after{
        height: 84px;
      }
      .gallery-slider{
        .image-block{
          width: 102px !important;
          height: 80px;
        }
      }
    }
  }
  .about-ellipse{
    bottom:-210px;
    left:-160px;
    width: 264px;
    height: 264px;
  }
}
