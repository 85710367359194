@import "../_variables";
@import "../_mixins";

.under-header{
  margin-top: 0;
  height: 610px;
  padding-bottom: 78px;
  .main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .ellipse-under-header{
      display: none;
    }
  }
  .name-site{
    display: none;
  }
  .logo{
    display: block;
    text-align: center;
  }
  .heading{
    font-size: 27px;
    margin-bottom: 10px;
    margin-top: 52px;
    text-align: center;
  }
  .description{
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .phone-number{
    font-size: 18px;
    text-align: center;
    line-height: 22px;
    margin-top: 70px;
  }
}
