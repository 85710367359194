@import "_variables";
@import "_mixins";

.request-form{
  //background-image: url("../images/other/request-form-bg.png");
  background-color: $main_dark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 0 30px;
  label:not(.checkbox-class){
    opacity: 0;
    height: 0;
    font-size: 0;
  }
  .main-container{
    display: flex;
    justify-content: space-between;
    .description-container{
      .heading{
        color: $main_light_grey;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
      }
      .description{
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        line-height: 22px;
        color: $main_light_grey;
      }
    }
    form{
      margin-left: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 414px;
      .form-input{
        padding: 12px 10px;
        border: 1px solid $main_light_grey;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: $main_light_grey;
        margin-top: 20px;
        &:first-child{
          margin-top: 0;
        }
        &::placeholder{
          color: $main_light_grey;
        }
      }
      .checkbox-block{
        margin:15px 0;
        input[type="checkbox"]{
          display: none;

          &:checked{
            &+label{
              .checkbox{
                &>.checkbox-checked{
                  background-color: $main_turquoise;
                }
              }
            }
          }
          &+label{
            @include vertical-flex-center();
            color: $main_light_grey;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            .checkbox{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 28px;
              height: 28px;
              margin-right: 20px;
              border: 1px solid $main_light_grey;
              box-sizing: border-box;
              padding: 2px;
              &>.checkbox-checked{
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: background-color ease 0.2s;
              }
            }
          }
        }
      }
      button[type="submit"]{
        background-color: $main_turquoise;
        font-weight: 400;
        font-size: 25px;
        line-height: 40px;
        color:$main_dark;
        text-transform: uppercase;
        padding: 5px 0;
        transition: color ease 0.3s;
        position: relative;
        height: 50px;
        &:hover{
          color: #ffffff;
        }

        .submit-text{
          opacity: 1;
          transition: opacity 0.1s ease-in-out;
          &.hide{
            opacity: 0;
          }
        }
        .submit-update{
          opacity: 0;
          transition: opacity 0.1s ease-in;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          top:20%;
          left:46%;
          position: absolute;
          border-color:#ffffff;
          border-width:3px;
          border-left-color:$main_turquoise;
          border-style: solid;
          animation: rotating 2s linear;
          animation-iteration-count: infinite;
          &.show{
            opacity: 1;
          }
        }
        //span{
        //  width: 40px;
        //  border-color:#bbbbbb;
        //  border-width:3px;
        //  //font-size:14px;
        //  border-left-color:#1ECD97;
        //  animation: rotating 2s 0.25s linear infinite;
        //
        //}
      }
    }
    .modal-window {
      top: 40%;
      .request-form-modal{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        padding: 50px;

        .check-container{
          border-radius: 50px;
          border: 2px solid $main_turquoise;
          picture{
            display: flex;
          }
        }

        .description{
          text-align: center;
          color: $main_dark;
          font-weight: 600;
          font-size: 24px;
          margin: 20px 0;
        }

        .close-modal{
          background-color: $main_turquoise;
          color: #ffffff;
          border-radius: 4px;
          padding: 6px 10px;
          display: inline-block;
          margin: 0 auto;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
