@import "_variables";
@import "_mixins";

.under-header{
  margin-top: -90px;
  z-index: 50;
  position: relative;
  //background-image: url("../images/other/under-header.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 125px;
  overflow: hidden;
  .main-container{
    position: relative;
    .ellipse-under-header{
      width: 732px;
      height: 732px;
      position: absolute;
      top: -480px;
      left: 550px;
      background-image: url("../images/other/ellipse-big-white.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .name-site{
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    color: $main_turquoise;
    margin-bottom: 65px;
  }
  .logo{
    display: none;
  }
  .heading{
    color: $main_turquoise;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 55px;
  }
  .description{
    text-transform: uppercase;
    font-size: 35px;
    line-height: 40px;
    color: $main_light_grey;
    font-weight: 900;
    strong{
      font-weight: 900;
    }
  }
  .phone-number{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #FBFAFA;
    margin-top: 110px;
    display: inline-flex;
  }
  .separate-bg{
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    top:0;
    right: 0;
    z-index: -1;
    background: rgb(0,0,0);
    //background: linear-gradient(
    //                110deg,
    //                rgba(0,0,0,0.7) 53%,
    //                rgba(57, 92, 92, 0.9) 50%,
    //                rgba(124,200,201,0.9) 100%
    //);
    background: linear-gradient(
                    103deg,
                    rgba(44,43,58,0.6) 57%,
                    rgba(57,92,92,0.6) 57%
                    //rgba(57,92,92,0.6) 67%,
                    //rgba(0,0,0,0.7) 100%
                    //rgba(57,92,92,0.6) 100%
    );
  }
}
