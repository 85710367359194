@import "../_variables";
@import "../_mixins";

.rates-container{
  padding-top: 40px;
  padding-bottom: 54px;
  .heading{
    font-size: 30px;
  }
  .rates{
    width: 672px;
    .rate-block{
      width: 212px !important;
      height: 220px;

      &:nth-child(3n+2),
      &.second{
        .rate-ellipse{
          top:130px;
          left:-180px;
        }
      }
      &:nth-child(3n+3),
      &.third{
        .rate-ellipse{
          bottom:-10px;
          left:-50px;
        }
      }
    }
  }
}
