@import "_fonts";
@import "_normalize";
@import "_variables";
@import "_mixins";
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import "~video-react/styles/scss/video-react";


html,
body{
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  touch-action: manipulation;
}

.main-body-content{
  opacity: 0;
  &.show{
    opacity: 1;
    transition: 0.3s ease opacity;
  }
}

a{
  text-decoration: none;
  color: inherit;
}
ul>li{
  list-style-type: none;
}

#scroll-top-button{
  display: flex;
  opacity: 0;
  position: fixed;
  bottom: 120px;
  left:152px;
  z-index: 999999;
  flex-direction: column;
  align-items: center;
  transition: all linear 0.3s;
  &.show{
    opacity: 1;
  }
  span{
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    color: $main_turquoise;
  }
}

.main-container{
  width: 852px;
  margin: 0 auto;
}

@import "_modal-window";
@import "_header";
@import "_under-header";
@import "_about";
@import "_disciplines";
@import "_coaches";
@import "_request-form";
@import "_rates";
@import "_timetable";
@import "_news";
@import "_gallery";
@import "_contacts";
@import "_footer";
@import "_arrows-slider";

@import "mobile/styles";
@import "tablet/styles";
