@import "../_variables";
@import "../_mixins";

.slick-arrow{
  &.slick-next{
    right: -10px;
    z-index: 10;
  }
  &.slick-prev{
    left: -10px;
    z-index: 10;
  }
}

.slick-dots{
  li{
    margin: 0 8px 0 0;
    width: 10px;
    height: 10px;
    &:last-child{
      margin:0;
    }
    &.slick-active{
      button{
        &::before{
          color: $main_turquoise;
          opacity: 1;
        }
      }
    }
    button{
      width: 48px;
      height: 48px;
      margin-top: -20px;
      &::before{
        width: 10px;
        height: 10px;
        font-size: 10px;
        line-height: 10px;
        color: $main_light_grey;
        opacity: 1;
        transition: color ease 0.3s;
      }
      &:hover::before{
        color: $main_turquoise;
      }
    }
  }
}

.news-container{
  .slick-dots{
    li:not(.slick-active){
      button{
        &::before{
          color: #ffffff;
        }
      }
    }
  }
}

.coaches-list{
  .slick-dots{
    bottom: -4px;
  }
}

.list-image{
  .slick-arrow{
    top: 50%;
    transform: translateY(-50%);

    &.slick-next{
      left:254px;
    }
  }
}

.timetable{
  .slick-arrow{
    bottom: -42px;
    transform: none;
    top: auto;

    &.slick-prev{
      left:0;
    }
    &.slick-next{
      right: 0;
    }
  }
}

.main-gallery {
  .slick-arrow{
    &.slick-prev{
      left:-20px;
    }
    &.slick-next{
      right: 0;
    }
  }
}
