@import "../_variables";
@import "../_mixins";

.main-contacts{
  padding: 28px 0;
  .content{
    .heading{
      font-size: 30px;
    }
    .content-block{
      .text-content-block{
        font-size: 16px;
      }
    }
    .contacts-links{
      .contact-email{
        font-size: 16px;
      }
    }
  }
  .contact-ellipse{
    bottom: -160px;
    right: -140px;
    width: 330px;
    height: 330px;
  }
}
