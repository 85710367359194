@import "../_variables";
@import "../_mixins";

.main-contacts{
  padding: 15px 0 0;
  .main-container{
    display: block;
  }
  .content{
    margin-bottom: 12px;
    .heading{
      font-size: 25px;
      line-height: 30px;
    }
    .content-block{
      margin-top: 15px;
      .text-content-block{
        font-size: 14px;
      }
    }
    .contacts-links{
      @include vertical-flex-center();
      margin-top: 15px;
      justify-content: space-between;

      .contact-email{
        margin-top: 0;
        font-size: 14px;
      }
      .social-links{
        margin-top: 0;
        a{
          margin-right: 12px;
        }
      }
    }
  }
  .contact-ellipse{
    display: none;
  }
}
