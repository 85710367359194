@import "../_variables";
@import "../_mixins";

.coaches{
  padding: 48px 0 10px;
  .heading{
    font-size: 52px;
    line-height: 52px;
  }
  .description{
    margin-top: -16px;
    font-size: 30px;
  }

  .coaches-list{
    width: 632px;
    margin: 0 auto;
    .slick-list{
      padding: 30px 0;
      margin-left: 40px;
    }
  }
}
