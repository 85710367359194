@import "../_variables";
@import "../_mixins";

.timetable-container{
  padding: 0 0 34px;
  .timetable{
    .content-timetable{
      padding-bottom: 12px;
      margin-bottom: 42px;
      border-bottom: 2px solid $main_turquoise;
    }
  }
}
