@import "../_variables";
@import "../_mixins";

.main-footer{
  padding: 12px 0;
  .main-container{
    display: block;
    .copyright{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div{
        width: 180px;
      }
      .link-on-developer{
        margin-top: 0;
        width: 100px;
      }
    }
    .contacts{
      display: none;
    }
  }
}
