@import "../_variables";
@import "../_mixins";

.request-form{
  background-size: cover;
  padding: 14px 0;
  .main-container{
    display: block;
    .description-container{
      .heading{
        font-size: 25px;
        line-height: 40px;
      }
      .description{
        font-size: 14px;
        margin-top: 6px;
        line-height: 20px;
        br{
          display: none;
          &:first-child{
            display: block;
          }
        }
      }
    }
    form{
      margin-left: 0;
      margin-top: 16px;
      width: auto;
      .form-input{
        margin-top: 8px;
      }
      .checkbox-block{
        margin:8px 0 30px;
        input[type="checkbox"]{
          &+label{
            align-items: flex-start;
            .checkbox{
              margin-top: 4px;
              width: 28px;
              height: 20px;
              margin-right: 12px;
              padding: 1px;
            }
          }
        }
      }
      button[type="submit"]{
        font-size: 18px;
        &:hover{
          color: $main_dark;
        }
      }
    }
  }
}
