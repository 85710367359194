@import "../_variables";
@import "../_mixins";

.coaches{
  padding: 32px 0 28px;
  .heading{
    font-size: 42px;
  }
  .description{
    margin-top: -18px;
    font-size: 25px;
    line-height: 28px;
  }

  .coaches-list{
    width: auto;
    .slick-list{
      padding: 20px 0;
    }
  }

  .coach-block{
    margin: 0 auto;
  }
}
