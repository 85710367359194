@import "_variables";
@import "_mixins";

.coaches{
  padding: 62px 0 38px;
  overflow: hidden;
  .main-container{
    position: relative;
  }
  .heading{
    color: $main_turquoise;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 80px;
    line-height: 40px;
  }
  .description{
    margin-top: -5px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    color: $main_dark;
    font-weight: 900;
  }

  .coaches-list{
    width: 892px;
    .slick-list{
      padding: 30px 0;
    }
  }

  .coach-block{
    width: 256px !important;
    height: 355px;
    perspective: 1000px;
    &.active .rotate{
      transform: rotateY(-180deg);
    }
    .rotate{
      width: 100%;
      height: 100%;
      position: relative;
      transition: 0.5s;
      transform-style: preserve-3d;
      .front{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 20px;
        backface-visibility: hidden;
        z-index: 2;
        .coach-photo{
          background-color: #000000;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          box-shadow: inset 0 -120px 30px -10px rgba(124,200,201,.75);
        }
        .coach-info{
          display: flex !important;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          &>.coach-name{
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            margin-bottom: 4px;
            text-align: center;
          }
          .rank{
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #ffffff;
            text-align: center;
            min-height: 36px;
            display: flex;
            align-items: center;
          }
          .button-more{
            color: $main_dark;
            padding: 8px 10px;
            border: 1.5px solid $main_dark;
            margin: 14px 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;
            transition: color ease 0.3s;
            text-align: center;
            &:hover{
              color:#ffffff;
              background: $main_dark;
            }
          }
        }
      }
      .back{
        background-color: $main_dark;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 38px 22px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        backface-visibility: hidden;
        transform: rotateY(-180deg);
        z-index: 2;
        .coach-name{
          margin-bottom: 16px;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: $main_turquoise;
        }
        .coach-description{
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          flex: 1;
          overflow-y: scroll;
          -ms-overflow-style: none; /* IE 11 */
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }
        .button-return{
          display: inline-flex;
          color: $main_turquoise;
          padding: 8px 20px;
          border: 1.5px solid $main_turquoise;
          margin: 14px 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          cursor: pointer;
          transition: color ease 0.3s;
          text-align: center;
          &:hover{
            color:#ffffff;
            background: $main_turquoise;
          }
        }
      }
    }
  }

  .coaches-ellipse{
    position: absolute;
    overflow: hidden;
    top:-350px;
    right:-340px;
    width: 578px;
    height: 578px;
    background-size: 100% 100%;
    background-image: url("../images/other/ellipse-turquoise.png");
    background-repeat: no-repeat;
    z-index: -1;
  }
}
