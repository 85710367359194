@import "_variables";
@import "_mixins";

.timetable-container{
  padding: 0 0 70px;
  .heading{
    color: $main_turquoise;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 75px;
    line-height: 40px;
  }
  .description{
    margin-top: -5px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    color: $main_dark;
    font-weight: 900;
  }

  .timetable{
    margin-top: 42px;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .content-timetable{
      .col-timetable{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .header-timetable{
          background: $main_turquoise;
          padding: 6px 0 6px 4px;
          font-size: 14px;
          font-weight: 400;
          color: $main_dark;
        }

        .cell-timetable{
          flex:1;
          max-width: 108px;
          min-height: 96px;
          height: 100%;
          overflow: hidden;
          padding: 8px 0 12px 4px;
          border-bottom: 2px solid $add_light_grey;
          &.empty{
            border-bottom-color: transparent;
          }
          &:last-child{
            margin-right: 0;
            border-bottom-color: transparent;
          }
          .time-start{
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 14px;
            color: $main_turquoise;
          }
          .name-workout{
            color: $main_dark;
            font-weight: 700;
            line-height: 18px;
            font-size: 14px;
          }
          .duration-workout{
            font-size: 14px;
            font-weight: 400;
            color: $main_dark;
          }
        }
      }
    }
  }
}
