@import "_variables";
@import "_mixins";

.main-footer{
  background: $main_dark;
  padding: 15px 0;
  .main-container{
    display: flex;
    justify-content: space-between;
    .copyright{
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
      color: #ffffff;
      .link-on-developer{
        margin-top: 8px;
      }
      span{
        text-decoration: underline;
      }

    }
    .contacts{
      display: flex;

      .phone-number{
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        color: #ffffff;
      }
      .social-links{
        margin-left: 26px;
        a{
          margin-right: 20px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }

    &.footer-links-container{
      display: block;
      margin-top: 10px;
      .footer-links{
        margin: 10px auto 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a{
          font-size: 12px;
          margin: 0 10px 10px 0;
          color: #ffffff;
          text-align: center;
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
