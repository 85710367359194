@import "../_variables";
@import "../_mixins";

.news-container{
  padding: 26px 0 38px;
  .heading{
    font-size: 48px;
  }
  .description{
    margin-top: -15px;
    font-size: 25px;
    line-height: 30px;
  }
  .news-list{
    margin: 30px auto 0;
    width: auto;
    .news-block{
      width: 256px !important;
      margin: 0 auto;
      &:hover{
        background-color: transparent;
      }
      .button-more-not-desktop{
        display: flex;
      }
      .news-bg{
        display: none;
      }
    }
  }

  .news-content-container{
    &.opened{
      max-height: 380px;
      padding-top: 0;
    }
    .news-content{
      margin: 0 14px;
      z-index: 1;
      padding: 20px;
      &:before{
        display: none;
      }
      .close-window{
        display: none;
      }
      .button-close-not-desktop{
        display: flex;
      }
    }
  }

  .main-container{
    .news-ellipse{
      top:-190px;
      left: auto;
      right:0;
      width: 342px;
      height: 342px;
    }
    .news-double-ellipse{
      display: none;
    }
  }
}
