@import "_variables";
@import "_mixins";

.slick-arrow{
  display: flex;
  align-items: center;
  &:before{
    content: none;
  }
  &.slick-next{
    justify-content: flex-end;
    right: 10px;
    z-index: 10;
    img{
      transform: rotate(180deg);
    }
  }
  &.slick-prev{
    justify-content: flex-start;
    left: -30px;
  }
}

.slick-dots{
  li{
    margin: 0 8px 0 0;
    width: 10px;
    height: 10px;
    &:last-child{
      margin:0;
    }
    &.slick-active{
      button{
        &::before{
          color: $main_turquoise;
          opacity: 1;
        }
      }
    }
    button{
      width: 48px;
      height: 48px;
      margin-top: -20px;
      &::before{
        width: 10px;
        height: 10px;
        font-size: 10px;
        line-height: 10px;
        color: $main_light_grey;
        opacity: 1;
        transition: color ease 0.3s;
      }
      &:hover::before{
        color: $main_turquoise;
      }
    }
  }
}

//.list-image{
//  .slick-arrow{
//    top: -38px;
//    width: 30px;
//    height: 36px;
//    background: $main_turquoise;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    &.slick-prev{
//      left: 0;
//    }
//    &.slick-next{
//      left:36px;
//    }
//  }
//}
