@import "../_variables";
@import "../_mixins";

.news-container{
  .heading{
    font-size:58px;
  }
  .description{
    margin-top: -10px;
    font-size: 30px;
  }
  .news-list{
    width: 632px;
    margin: 30px auto 0;
    .slick-list{
      margin-left: 40px;
    }
    .news-block{
      &:hover{
        background-color: transparent;
      }
      .button-more-not-desktop{
        display: flex;
        &.hide{
          margin-bottom: 0;
        }
      }
      .news-bg{
        display: none;
      }
    }
  }

  .news-content-container{
    width: 552px;
    margin: 0 auto;
  }

  .main-container{
    .news-ellipse{
      top:-190px;
      left:50px;
      width: 340px;
      height: 340px;
    }
    .news-double-ellipse{
      width: 320px;
      height: 320px;
      top:-250px;
      right: -180px;
    }
  }
}
