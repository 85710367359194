@import "_variables";
@import "_mixins";

.disciplines-container{
  background-color: $main_dark;
  padding: 32px 0 10px;
  //background-image: url("../images/other/disciplines-bg.png");
  background-repeat: no-repeat;
  background-position: center;

  .heading{
    font-weight: 900;
    font-size: 40px;
    color:$main_light_grey;
  }
  .disciplines{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;

    .discipline-block{
      width: 256px;
      height: 270px;
      overflow: hidden;
      position: relative;
      margin-bottom: 30px;
      &>img{
        width: 100%;
      }
      @media (min-width: 1280px) {
        &.active,
        &:hover{
          .description{
            max-height: 270px;
            .text{
              opacity: 1;
            }
          }
        }
      }
      .description{
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        background-color: #ffffff;
        padding: 0 20px;
        max-height: 65px;
        min-height: 65px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: max-height ease 0.5s;
        &:after{
          position: absolute;
          right:0;
          top:0;
          content: " ";
          width: 0;
          height: 0;
          border-top: 22px solid #7cc8c9;
          border-left: 22px solid transparent;
        }
        .title{
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: $main_turquoise;
          margin-top: 20px;
        }
        .text{
          flex: 1;
          opacity: 0;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          transition: opacity ease 0.3s;
        }
        .button-more-not-desktop{
          display: none;
          margin-top: 5px;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: $main_turquoise;
          text-transform: uppercase;
          transition: all ease 0.3s;
          span{
            margin-left: 5px;
          }
          &.hide{
            margin-bottom: -12px;
          }
        }
        .button-more{
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: $main_turquoise;
          padding: 8px 10px;
          border: 1px solid $main_turquoise;
          width: 102px;
          text-align: center;
          cursor: pointer;
          margin-bottom: 10px;
          transition: background-color ease 0.3s;
          &:hover{
            color: #ffffff;
            background-color: $main_turquoise;
          }
        }
      }
      .discipline-line{
        display: none;
      }
    }
  }
  .discipline-content-container{
    display: none;
  }
}
