@import "../_variables";
@import "../_mixins";

.request-form{
  padding: 20px 0;
  .main-container{
    .description-container{
      .heading{
        font-size: 30px;
        line-height: 40px;
      }
      .description{
        font-size: 14px;
      }
    }
    form{
      width: 514px;
      .checkbox-block{
        input[type="checkbox"]{
          &+label{
            .checkbox{
              width: 32px;
              margin-right: 16px;
            }
          }
        }
      }
      button[type="submit"]{
        font-size: 20px;
        padding: 1px 0;
      }
    }
  }
}
