@import "../_variables";
@import "../_mixins";

@media (max-width: 767px) {
  #scroll-top-button{
    display: none;
  }

  .main-container{
    width: 320px;
    padding: 0 18px;
  }

  @import "_header";
  @import "_under-header";
  @import "_about";
  @import "_disciplines";
  @import "_coaches";
  @import "_request-form";
  @import "_rates";
  @import "_timetable";
  @import "_news";
  @import "_contacts";
  @import "_footer";
  @import "_arrows-slider";
  @import "_gallery";
}
