@import "../_variables";
@import "../_mixins";

.about{
  padding: 15px 0 20px;
  .main-container{
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .about-content{
    .heading{
      font-size: 48px;
    }
    .description{
      font-size: 25px;
      line-height: 25px;
    }
    .text{
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      margin-right: 0;
    }
  }
  .gallery{
    .active-image{
      display: none;
    }
    .list-image{
      margin-top: 0;
      width: 320px;
      .gallery-slider{
        .image-block{
          width: 284px !important;
          height: 198px;

          .video-react{
            .video-react-big-play-button{
              display: block;
            }
          }

        }
      }
      &:after{
        content: "";
        position: absolute;
        right: -22px;
        top:0;
        width: 40px;
        height: 198px;
      }
    }
  }
  .about-ellipse{
    display: none;
  }
}
