@import "_variables";
@import "_mixins";

.news-container{
  background-color: $main_light_grey;
  padding: 26px 0;
  overflow: hidden;
  .heading{
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 75px;
    line-height: 40px;
  }
  .description{
    margin-top: -5px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 40px;
    color: $main_dark;
    font-weight: 900;
    position: relative;
    z-index: 1;
  }
  .news-list{
    width: 892px;
    margin-top: 30px;
    position: relative;
    z-index: 1;
    .slick-track{
      float: left;
    }
    .news-block{
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      width: 256px !important;
      height: 266px;
      position: relative;
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      padding: 22px;
      box-shadow: inset 0 -60px 30px -10px rgba(56,54,71,.75);
      &:hover{
        background-color: $background_dark_rate;
      }
      .image-block{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .news-date{
        position: absolute;
        top:30px;
        right: 0;
        background-color: $main_turquoise;
        padding: 2px 6px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
      .news-title{
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: #ffffff;
      }
      .button-more-not-desktop{
        display: none;
        margin-top: 20px;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $main_turquoise;
        text-transform: uppercase;
        transition: all ease 0.3s;
        span{
          margin-left: 5px;
        }
        &.hide{
          margin-bottom: -40px;
        }
      }
      .news-bg{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color ease 0.3s;
        &:hover{
          background-color: $background_dark_rate;
          .button-more{
            opacity: 1;
          }
        }
        .button-more{
          opacity: 0;
          cursor: pointer;
          border: 1px solid $main_turquoise;
          color: $main_turquoise;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          width: 104px;
          padding: 8px 0;
          transition: background-color ease 0.3s;
          &:hover{
            background-color: $main_turquoise;
            color:#ffffff;
          }
        }
      }
    }
  }

  .news-content-container{
    padding-top: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    transition: all linear 0.3s;
    max-height: 0;
    overflow: hidden;
    &.opened{
      max-height: 404px;
      overflow-y: scroll;
      padding-top: 24px;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
      .news-content{
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    &.left .news-content:before{
      left:15px;
    }
    &.center .news-content:before{
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }
    &.right .news-content:before{
      right: 15px;
    }
    .news-content{
      padding: 0 40px 0 20px;
      background: #ffffff;
      transition: padding linear 0.3s;
      position: relative;
      &:before{
        position: absolute;
        content: " ";
        width: 0;
        height: 0;
        border-top: 22px solid #ffffff;
        border-left: 22px solid transparent;
        transform: rotate(-45deg);
        top:-10px;
      }
      .close-window{
        position: absolute;
        top:4px;
        right: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
      }
      .button-close-not-desktop{
        display: none;
        margin-top: 20px;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $main_turquoise;
        text-transform: uppercase;
        img{
          transform: rotate(180deg);
        }
        span{
          margin-left: 5px;
        }
      }
    }
  }

  .main-container{
    position: relative;

    .news-ellipse{
      position: absolute;
      top:-300px;
      left:40px;
      width: 524px;
      height: 524px;
      background-image: url("../images/other/ellipse-white.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

    }
    .news-double-ellipse{
      width: 494px;
      height: 494px;
      position: absolute;
      background-image: url("../images/other/ellipse-double-white.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      top:-380px;
      right: -350px;

    }
  }
}
