@import "../_variables";
@import "../_mixins";

.timetable-container{
  padding: 0 18px 28px;
  .heading{
    font-size: 35px;
  }

  .timetable{
    margin-top: 20px;

    .content-timetable{
      padding-bottom: 6px;
      margin-bottom: 42px;
      border-bottom: 2px solid $main_turquoise;
      .col-timetable{
        .header-timetable{
          padding: 6px 0 6px 20px;
        }

        .cell-timetable{
          margin-right: 0;
          margin-left: 16px;
        }
      }
    }
  }
}
