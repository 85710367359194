@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Black.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-Black.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-Black.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-Black.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-Bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-SemiBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-SemiBold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-SemiBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Medium.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-Medium.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-Medium.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Light.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Montserrat-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Montserrat-Light.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Montserrat-Light.woff2") format("woff2"); /* Modern Browsers */
    font-weight: 300;
    font-style: normal;
}
