@import "../_variables";
@import "../_mixins";

@media (max-width: 1279px) and (min-width: 768px) {
  #scroll-top-button{
    display: none;
  }

  .main-container{
    width: 662px;
  }

  @import "_modal-window";
  @import "_header";
  @import "_under-header";
  @import "_about";
  @import "_disciplines";
  @import "_coaches";
  @import "_request-form";
  @import "_rates";
  @import "_timetable";
  @import "_news";
  @import "_contacts";
  @import "_arrows-slider";
  @import "_gallery";
}
