@import "_variables";
@import "_mixins";

.rates-container{
  padding-top: 42px;
  padding-bottom: 80px;
  .heading{
    color: $main_dark;
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .rates{
    width: 892px;
    .rate-block{
      position: relative;
      width: 256px !important;
      height: 270px;
      overflow: hidden;
      background-color: $background_dark_rate;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bg-image{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: contain;
      }
      .duration-rate{
        position: absolute;
        top:20px;
        right: 18px;
        font-size: 14px;
        color: $main_light_grey;
        font-weight: 400;
      }
      .content-rate{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 80px;
        text-align: center;

        .name-rate{
          text-transform: uppercase;
          font-weight: 900;
          color:$main_turquoise;
          line-height: 22px;
          flex: 1;
          width: 65%;
          @include vertical-flex-center();
        }
        .price-rate{
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color:$main_light_grey;
        }
      }
      .rate-ellipse{
        position: absolute;
        overflow: hidden;
        width: 404px;
        height: 404px;
        background-size: 100% 100%;
        background-image: url("../images/other/ellipse-turquoise-small.png");
        background-repeat: no-repeat;
        bottom: 0;
      }

      &:nth-child(3n+1),
      &.first{
        .rate-ellipse{
          bottom:20px;
        }
      }
      &:nth-child(3n+2),
      &.second{
        .rate-ellipse{
          top:180px;
          left:-140px;
        }
      }
      &:nth-child(3n+3),
      &.third{
        .rate-ellipse{
          bottom:36px;
          left:-40px;
        }
      }
    }
  }

}
