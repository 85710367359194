@import "../_variables";
@import "../_mixins";

.rates-container{
  padding-top: 26px;
  padding-bottom: 60px;
  .heading{
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 14px;
  }
  .rates{
    width: auto;
    .rate-block{
      margin: 0 auto;
    }
  }
}
