@import "_variables";
@import "_mixins";

.main-gallery{
  padding: 60px 0 0;

  .heading {
    color: $main_dark;
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .slider {
    width: 892px;
    .image-block{
      padding-right: 20px;
      width: calc(100% - 20px) !important;
      img {
        height: 560px;
        width: 100%;
      }
    }
  }
}
